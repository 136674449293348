.deliveries_list {
    overflow-y: auto; /* Make the list scrollable */
  }

.deliveries_map{
    width: 80%;
    height: 100%; /* Ensure the map takes the full height */
        background-color: rgb(219, 219, 219);
}

.full_height {
    height: 100vh; /* Full height of the viewport */
  }

.map_list_area{
    display: flex;
    width: 100%;
    height: 100vh;
    /* background-color: red; */
}

.sec{
    margin-top: 12px;
    margin-bottom: 15px;
}

.list_sect{
    /* background-color: red; */
    width: 40%;
}