.map_section_div{
  width:50%;
  position:absolute;
  background-color:#FFFFFF;
}


.accepted_deliveries_list{
    position:absolute;
    border-radius: 10px;
    background-color: #f3f2f2 !important;
    z-index: 2;
    padding: 8%;
    margin: 2%;
}
.accepted_deliveries_list> ul{
    margin: 0px;
    padding: 0px;
}

.maps-dd{
    width:70rem;
    height: 100vh;
    background-color: white;
    position:absolute;
}


.mmp{
    /* color: #216df0; */
    font-size: 1rem;
    margin-top: 5%;
    line-height: 1rem;
    font-weight: 500;
    letter-spacing: 0.025em;
    font-variant-numeric: unset;
}

.no-data{
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.select-delivery-text{

}

#list-list{
    list-style: none;
}


.steps{
    color: red;
    margin-top: 5%;
}

.contact-text{
    margin-top: 0px;
}


.del-name{
    margin-top: 0px;
}

.selected{
    list-style: none;
}