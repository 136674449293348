.cubeItem{
    background-color: #fcfcfc;
    display: flex;
    border-radius: 5%;
    border: 1px solid rgb(221, 220, 220);

    flex-direction: column;
}

.cube-image{
width: 100%;
border-top-left-radius: 10px;
border-top-right-radius: 10px;

}

.cube_title{
    margin: 0px;
    margin-bottom: 5%;
    margin-top: 5%;
}


.cube-desc{

}

.cube-holder-item{
    padding: 1%;
    margin-left: 1%;
    border-radius: 5%;
    align-content: center;
    border: 1px solid rgb(221, 220, 220);
    margin-right: 1%;
    margin-top: 5%;
    margin-bottom: 5%;
    background-color: #f1f1f1;
}

.cube-txt-section{

    padding: 0% 5% 5% 5%;
}