.delivery-item{
    /* background-color: red; */
cursor: pointer;
padding: 12px;

list-style: none;
}


.notification{
    color: green;
    font-size: large;
    margin-top: 1%;
}

.non-dels{
    color: red;
    font-size: medium;
    margin-top: 1%;
}

.del-map{
    background-color: #f4f4f4;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.close-button{
    width: 100%;
    margin-top: 1%;
    margin-bottom: 1%;
}



.delivery-item:hover{
    background-color: #f4f4f4;
}


.del-title{
    font-size: 2.05rem;
    line-height: 2.5rem;
    font-weight: 600;
    margin-top: 0px;
    letter-spacing: 0;
    margin-bottom: 0px;
    text-transform: none;
    width: 50%;
    font-variant-numeric: unset;
}

.actions{
    background-color: rgb(243, 240, 240);
    height: fit-content;
    width: 100%;
    padding: 12px;
}


.ac-d{
    margin-top: .5rem;
    width: 20%;
    margin-left: 1rem;
}

.ac-d2{
    margin-top: .5rem;
    width: 20%;
    color: white;
    background-color: #216df0;
}