.blockHeader {
  display: block;
}

.new-header{
    display: none;
}

.story_header{
  display: block;
}

@media only screen and (max-width: 800px) {
  .blockHeader {
    z-index: 9999;
    display: none !important;
    padding: 10px 1rem !important;
    height: 6rem;
    transition: box-shadow 0.3s ease-in-out;
  }
  .menu-mobile {
  }

  .about-content {
    display: flex;
    flex-direction: column;
    padding-top: 2rem !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    background-color: whitesmoke;
}

.courses-grid {
  display: grid;
  grid-template-columns: repeat(1, 2fr) !important;
  gap: 1.5rem;
}

.courses-cta {
  width: 100%;
  margin-top: 3rem !important;
  align-items: center;
  display: flex;
  background-color: rgba(201, 201, 201, 0.247);
  justify-content: center;
  flex-direction: column;
  padding: 2rem !important;
  height: fit-content !important;
  border-radius: 10px;
}

.ccta-text {
  font-weight: 500;
  width: 100% !important;
  color: rgba(0, 0, 0, 0.719);
  font-size: large;
}


.mobile-menu-text {
  font-size: larger;
  cursor: pointer;
  color: white !important;
}

.link-style{
  text-decoration: none;
  color: white !important;
}



.courses-container-2 {
  width: 100%;
  text-align: center;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.ab-section {
  display: flex !important;
  height: 100% !important;
  flex-direction: column;
  margin-top: 2rem !important;
}
  

.story_header{
  display: none !important;
}

  .twg-div1 {
    display: flex;
    width: 100% !important;
    justify-content: center;
    flex-direction: column;
}


  .new-header-logo{
width: 70px;
padding-left: 1rem;
  }

  .backhome-text{
    font-size: smaller;
color: black;
  }

  .back-home-section{
    display: flex;
margin-left: 2rem;
    padding: .6rem;
    border-radius: 20px;
    background-color: #c0c0c0;
  }

  .new-ic{
    color: black;
    font-size: small;
    padding-right: .5rem;
  }


    .new-header {
        /* background-color: red; */
        display: flex;
        align-items: center;
        display: none;

        width: 100%;
        /* padding-left: 2rem;

        padding-right: 2rem; */
        justify-content: space-between;
        height: 5rem;
        z-index: 9999999;
        position: absolute;
    }



  .mobile-ic {
    font-size: 1.8rem;
    font-weight: 900;
  }

  .mobile-menu {
    z-index: 9999;
    padding: 10px 1rem !important;
    height: 6rem !important;
    justify-content: space-between;
    position: relative;
    width: 100%;
    box-shadow: -6px 0 rgba(5, 2, 2, 0.377), 6px 0 rgba(0, 0, 0, 0.062),
      0 7px 5px -2px #00000026;
    transition: box-shadow 0.3s ease-in-out;
    background-color: rgb(255, 255, 255);
    display: flex;
    /* background-color: red; */
    align-items: center;
    padding: 10px 12rem;
    position: fixed;
  }

  .content_home {
    /* background-color: green; */
    margin: 0px !important;
    width: 100% !important;
    padding: 0px !important;
  }


  .why-us {
    color: #216df0;
    font-size: 2rem;
    margin-top: 5%;
    line-height: 1rem;
    font-weight: 500;
    letter-spacing: normal !important;
    text-transform: uppercase;
    font-variant-numeric: unset;
}

  .imageside {
    border-radius: 20px;
    width: 99% !important;
    background-attachment: fixed;
    height: 20rem !important;
    position: relative;
    margin-top: 2rem;
    display: none;
    background-repeat: no-repeat;
    background-size: cover;
    
  }

  .testHolder {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column !important;
    width: 100%;
    padding-top: 2.5rem !important;
    padding-bottom: 4rem !important ;
    background-color: #f5f7fb;
  }

  .getStarted {
    width: 100%;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    display: flex;
    padding-bottom: 5rem !important;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .reduce-text {
    font-size: 1rem !important;
    line-height: normal !important;
    font-weight: 600;
    color: white;
    text-align: center;
    margin-top: 0px;
    letter-spacing: normal;
    margin-bottom: 0px;
    text-transform: none;
    width: 100% !important;
    font-variant-numeric: unset;
}

.start-here {
    margin-top: 4% !important;
    width: 44% !important;
    font-size: 1rem;
    border-radius: 17px;
}
.ft_links {
    /* font-size: 1.5rem !important; */
    color: #c0c0c0 !important;
    text-decoration: none !important;
}

.footer22_main {
    position: relative;
    /* width: 100%; */
    bottom: 0;
}

.overlay3 {
    position: relative;
    z-index: 2;
    background-color: rgba(29, 29, 29, 0.651);
    height: 100%;
    width: 100%;
    display: flex;
    padding-right: 2rem !important;
    text-align: left;
    flex-direction: column;
    padding-left: 2rem !important;
    color: black;
    justify-content: space-between;
    padding-bottom: 4rem;
    font-size: 24px;
}

.cta-ms {
    margin-bottom: 0rem !important;
    height: 20rem;
    display: flex;
    background-image: url("./assets/code2m.png") !important; 
    flex-direction: column;
    background-repeat: no-repeat;
    gap: 1em;
    align-items: center;
    background-size: cover !important;
    width: 100%;
}

.vc-btn {
    align-self: center;
    width: 100% !important;
    border-radius: 20px;
}

.inner-postcontent {
    background-color: whitesmoke;
    width: 100%;
    color: white;
    padding-top: 5rem;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}

  .footer22 {
    width: 100%;
    place-items: center;
    color: white;
    position: relative;
    height: auto;
    background-color: #0a2d5f !important;
  }

  .menu-mobile-icon{
    font-size: 1rem;
  }

  .logo {
    width: 4rem !important;
    cursor: pointer;
  }

  .text_hero {
    display: flex;
    margin-top: 7rem !important;
    flex-direction: column;
  }

  .tw-gradient {
    width: 100%;
    padding-left: 1rem !important;
    padding-top: 2.5rem !important;
    padding-bottom: 3rem !important;
    padding-right: 1rem !important;
    height: 100% !important;
    background-color: #f1f5fc;
  }

  .twg-txt {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2rem !important;
}

  .ai-powered-text {
    font-size: 1rem !important;
    line-height: normal !important;
    font-weight: 500;
    text-align: center;
    color: white;
    letter-spacing: normal !important;
    text-transform: uppercase;
  }

  .ai-powered-text2 {
    line-height: 1rem;
    color: white;
    font-size: 1rem !important;
    font-weight: 400;
    display: none;
    text-align: right;
    letter-spacing: 0.025em;
    font-variant-numeric: unset;
  }

  .tw-gradient-top {
    border-radius: 50px;
    background-color: #3b71d7;
    width: 100%;
    margin-bottom: 0%;
    margin-top: 2rem;
    justify-content: space-between !important;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .twg-title {
    width: 100% !important;
    font-size: 1.5rem !important;
    line-height: normal !important;
    font-weight: 300;
    letter-spacing: 0;
    text-align: center;
    margin-top: 3rem !important;
    text-transform: none;
    font-variant-numeric: unset;
    margin-bottom: 2rem;
    color: gray;
}

.twg-subTitle {
    color: #19497e;
    margin-top: 0rem !important;
    font-size: 1rem !important;
    align-self: center;
    line-height: 2.75rem;
    font-weight: 500;
    letter-spacing: 0;
    text-transform: none;
    font-variant-numeric: unset;
}


.twg-p {
    font-size: 1rem !important;
    margin-bottom: 2rem !important;
    letter-spacing: rem;
    margin-top: 3%;
    text-align: center;
    align-self: center;
}

.twg-btn {
    margin-bottom: 2rem;
    margin-top: 2rem;
    align-self: center;
    font-size: 2rem;
    display: none;

    width: 78% !important;
    border-radius: 39px !important;
}

  .twg-images {
    width: 100%;
    height: 100%;
    gap: 5px;
    display: flex;
    padding: 1%;
    flex-direction: column;
  }

  .twg-image-one {
    width: 100% !important;
    height: 90%;
    margin-bottom: 2rem;
    border-radius: 53px;
}

  .twg-image-one2 {
    width: 100% !important;
    height: 90%;
    margin-bottom: 1rem;
    border-radius: 53px;
  }

  .tw-gradient-inner-top {
    display: flex;
    background-color: white;
    width: 100%;
    height: 100%;
    flex-direction: column !important;
    margin-top: 0% !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    border-radius: 50px;
  }

  .items_section {
    width: 100%;
    height: 100%;
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .boost {
    color: rgb(46 58 52);
    width: 100% !important;
    margin-bottom: 1rem;
    font-size: 1rem;
    padding-left: 1rem;
    margin-bottom: 2rem;
    padding-right: 1rem;
    text-align: center !important;
  }

  .f-text {
    color: #216df0;
    margin-bottom: 2rem !important;
    max-width: 98% !important;
    font-size: 2.7rem !important;
    line-height: 2.5rem !important;
    font-weight: 700;
    text-align: center;
    margin-top: 4rem !important;
    letter-spacing: -0.00625rem !important;
    text-transform: none;
}

  .resizebtn {
    width: 85% !important;
    color: #216df0 !important;
    margin-top: 5%;

  }

  .resizebtn2 {
    width: 85% !important;
    margin-left: 1%;
    margin-top: 5%;
    color: #19497e !important;
    background-color: transparent !important;
}

.resizebtn2:hover{
    background-color: #216df0 !important;
    color: white !important;
}

  .btnArea {
    place-self: center;
    margin-bottom: 1rem;
    align-items: center;
    display: flex;
    width: 100%;
    flex-direction: column;
}

.newBtn {
    background-color: #e3effb;
    height: fit-content;
    font-size: 1rem !important;
    width: 100%;
    margin-top: 0px !important;
    margin-bottom: 0.5rem;
    border-radius: 17rem;
    color: #19497e;
    margin-left: 0px !important;
    box-shadow: 0 0px 1px #216df0, 0 1px 2px hsla(0, 0%, 0%, 0.2);
}

  .map_drivers_title {
    background-color: white;
    display: flex;
    padding-left: 2rem !important;
    flex-direction: column;
    padding-bottom: 2.5rem !important;
    padding-right: 2rem !important;
  }

  .homepage {
    background-color: rgb(228, 231, 231);
    width: fit-content;
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
  }

  .why_sub {
    font-size: 1rem !important;
    line-height: normal !important;
    font-weight: 300;
    letter-spacing: normal !important;
    text-transform: none;
    font-variant-numeric: unset;
}

.cube-desc {
    text-align: left;
    font-size: 1rem;
}


.how_txt {
    color: #0a51d8;
    font-size: 1rem !important;
    margin-top: 7rem;
    line-height: normal !important;
    font-weight: 500;
    letter-spacing: normal !important;
    text-transform: uppercase;
    font-variant-numeric: unset;
}

.cube_title {
    margin: 0px;
    font-size: 1.5rem;
    margin-bottom: 5%;
    margin-top: 5%;
}

  .cubes {
    display: flex;
    margin-bottom: 4rem;
    /* margin-top: 4rem; */
    flex-direction: column !important;
  }

  .sync_txt {
    font-size: 2rem !important;
    line-height: normal !important;
    font-weight: 300;
    margin-top: 0px;
    letter-spacing: normal;
    text-transform: none;
    font-variant-numeric: unset;
}

  .hero_section3 {
    margin-bottom: 0px !important;
    position: relative;
    background-color: black !important;
    overflow: unset !important;
  }

  .right_map2 {
    /* background-color: red; */
    width: 100% !important;
    border-radius: 10px;
    margin-top: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 0rem !important;
    float: none !important;
  }

  .right_map {
    margin-top: 2.5rem !important;
    width: 100% !important;
    margin-right: 0rem !important;
    float: none !important;
    margin-bottom: 10%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    /* background-color: red; */
  }

  .track-title {
    color: white;
    font-size: 2.8rem;
    line-height: normal;
    font-weight: 500;
    width: 80%;
    margin: 0px;
    letter-spacing: normal;
    margin-top: 3rem;
    text-transform: none;
    font-variant-numeric: unset;
}

.am-desc {
    font-size: 2rem;
    line-height: normal;
    font-weight: 400;
    letter-spacing: normal;
    margin-top: 3%;
    color: white;
    margin-bottom: 2rem;
    text-transform: none;
    font-variant-numeric: unset;
}

.trm_desc {
    color: white;
    font-weight: 300;
    font-size: 1rem;
}

.trm_title {
    font-size: 1.2rem !important;
    line-height: normal !important;
    font-weight: bold !important;
    letter-spacing: normal !important;
    text-transform: none !important;
    color: white !important;
    font-variant-numeric: unset !important;
}

.getStarted-title {
    font-size: 2rem;
    line-height: 1rem;
    margin-top: 12%;
    color: #e3effb;
    font-weight: 500;
    letter-spacing: .025em;
    text-transform: uppercase;
    font-variant-numeric: unset;
}

.ms-header{
    display: none !important;
}

#ic_trm {
    color: #0a51d8;
    font-size: 2rem;
    margin-right: 2%;
}

  .right_map_content {
    background-color: black;
    height: 100% !important;
    padding-left: 2rem;
    margin-top: 2rem;
    padding-right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .manage_drivers {
    background-color: white;
    border-radius: 20px;
    margin-bottom: 10%;
    display: none;
    align-self: center;
    width: 80% !important;
    height: 100% !important;
  }

  .white-line-container {
    display: none !important;
  }

  .hero_section2 {
    background-color: white;
    height: 100% !important;
    display: flex;
  }

  .hero_section {
    height: 100% !important;
    flex-direction: column !important;
    align-items: center;
    display: flex;
    background-color: white;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .cta-text {
    margin-bottom: 1rem;
    text-align: center !important;
    font-size: 1rem;
    color: black;
    font-weight: 400;
}









}
