.homepage{
    background-color: rgb(228, 231, 231);
    width: 100%;
    display: flex;
    flex-direction: column;
}


.content_home{
    /* background-color: green; */
    margin: 0px !important;
    padding: 0px !important;
}

.test{
    background-color: rgb(228, 231, 231);
}

