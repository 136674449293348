.guy-sama{
    height: 40%;
    border-radius: 5px;
    /* margin: 1%; */
    width: 100%;
    padding: 5%; 
    align-items: center;
    background-color: white;
    box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12); 
    display: flex;
}

.guy-text-left{
    align-content: center;
    width: 70%;
    align-items: center;
    margin-right: 1%;
    height: 200px;
}

.guy-image{
    border-radius: 7px;
    width: 30%;
    height: 200px;
display: flex;
flex-direction: column;
}

.spacer{
    height: 50%;
    width: 100%;
}

.guy{
    width: 100%;
    height: 70%;
    bottom: 0;

}

.greetings{
font-size: medium;
}

.overview-text{
margin-bottom: 1.3rem;
}

.sales-button{

}