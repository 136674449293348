.footer22_main{
  /* position: absolute; */
  width: 100%;
  /* bottom: 0; */
}


.link-ft{
  color: rgb(163, 163, 163) !important;
}

  .footer22 {
    width: 100%;
    place-items: center;
    position: relative;
    height: auto;
    background-color: #164b96;
  }
  .footer22 .col {
    width: 190px;
    height: auto;
    float: left;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 0px 20px 20px 20px;
  }
  .footer22 .col h1 {
    margin: 0;
    padding: 0;
    font-family: inherit;
    font-size: .8rem;
    line-height: 17px;
    padding: 20px 0px 5px 0px;
    color: rgba(255,255,255,0.2);
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.250em;
  }
  .ft_links {
    font-size: .8rem;
    color: #999999 !important;
    text-decoration: none !important;
  }

.ft_links:hover{
    color: white !important;
}

  .footer22 .col ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .footer22 .col ul li {
    color: #999999;
    font-size: .8rem;
    font-family: inherit;
    /* font-weight: bold; */
    padding: 1px 0px 1px 0px;
    cursor: pointer;
    transition: .2s;
    -webkit-transition: .2s;
    -moz-transition: .2s;
  }
  .social ul li {
    display: inline-block;
    padding-right: 5px !important;
  }
  
  .footer22 .col ul li:hover {
    color: #ffffff;
    transition: .1s;
    -webkit-transition: .1s;
    -moz-transition: .1s;
  }
  .clearfix {
    clear: both;
  }
  /* @media only screen and (min-width: 1280px) {
    .contain {
      width: 1200px;
      margin: 0 auto;
    }
  } */
  @media only screen and (max-width: 1139px) {
    .contain .social {
      width: 1000px;
      display: block;
    }
    .social h1 {
      margin: 0px;
    }
  }
  @media only screen and (max-width: 950px) {
    .footer22 .col {
      width: 33%;
    }
    .footer22 .col h1 {
    }
    .footer .col ul li {
    }
  }
  @media only screen and (max-width: 500px) {
      .footer22 .col {
        width: 50%;
      }
      .footer22 .col h1 {
      }
    .footer22 .col ul li {
  }
  }
  @media only screen and (max-width: 340px) {
    .footer22 .col {
      width: 100%;
    }
  }