.loginHolder{
    place-content: center;
    display: flex;
flex-direction: column;
width: 100% !important;
/* background-color: red; */
height: 100vh;
background-image: url("../assets/bg.png");
}

.navis-form{
    align-self: center;
    background-color: rgba(245, 245, 252, 0.651);
    height: fit-content;
    border-radius: 5px;
    width: 30%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}



.nav-input{
    height: 25%;
    border-radius: 5px;
    padding: 10px;
    width:100%;
    margin-bottom: 2%;
}

.navis-logo{
    align-self: center;
    width: 20%;
    margin-bottom: 1%;
}



.btn-login{
    width: 50%;
    /* height: 40px; */
    border-radius: 17px;
    color: #19497e;
    align-self: center;

}



/* .btn-login:hover{
    background-color: #216DF0;
} */
.back_btn{
    margin-top: 2%;
    border-radius: 17px;
    color: #19497e;
}

.btn-login:hover{
    background-color: #216DF0;
    color: white;
}
.version{
    color: gray;
    font-weight: lighter;
    font-size: small;
    margin-Top: 5%;
    align-self: center;
}

.termsConditions{
    width: 100%;
    color:gray;
    margin-top: 5%;
}

.btn-regesiter{
    width: 50%;
    /* height: 40px; */
    border-radius: 17px;
    background-color: transparent;
    align-self: center;
}
.btn-regesiter:hover{
    background-color: #216DF0;
    color: white;
}

.btns-login{
margin-top: 5%;
    display: flex;
    gap:10px;
}