.courses{
    display: flex;
    flex-direction: column;
    width: 100%;
}



:where(.css-dev-only-do-not-override-apn68).ant-message .ant-message-notice-wrapper .ant-message-notice-content {
  display: inline-block;
  margin-top: 2rem !important;
  padding: 9px 12px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
}

.courses-container-2 {
    width: 100%;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 5rem;
    padding-left: 12rem;
    padding-right: 12rem;
  }
  
.courses-title{
    text-align: left;
    margin-top: 6rem;
    margin-bottom: 3rem;
}

:where(.css-apn68).ant-modal .ant-modal-close {
  position: absolute;
  top: 10px;
  display: none !important;
  inset-inline-end: 12px;
  z-index: 1010;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s;
}

  .courses-container h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  
  .courses-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
  
  .course-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s;
  }
  
  .course-card:hover {
    transform: translateY(-5px);
  }

  .course-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .course-content {
    padding: 1rem;
    text-align: left;
  }
  
  .course-content h3 {
    font-size: 1.5rem;
    margin-bottom: 0rem;
  }
  
  .course-content p {
    color: #555;
  }
  
  .course-content h4 {
    margin-top: .5rem;
    font-size: 1rem;
  }
  
  .course-content ul {
    list-style-type: disc;
    margin-left: 0rem;
    margin-top: 0rem !important;
    color: #333;
    margin-bottom: 0rem !important;
  }
  
  .instructor {
    margin-top: .5rem;
    font-style: italic;
    color: #666;
    font-size: smaller;
}

.martha{
    margin-bottom: 1rem;
}
  

  .course-title{
    margin-top: 0rem;
  }


  .course-desc{

    margin-bottom: 1rem !important;
  }

  .course-topic-title{
    margin-bottom: 1px;
    margin-top: 1.5px !important;

  }

  .course-topic{

  }


  .courses-cta{
    width: 100%;
    margin-top: 3rem;
    align-items: center;
    display: flex;
    background-color: rgba(201, 201, 201, 0.247);
    justify-content: center;
    flex-direction: column;
    height: 20rem;
    border-radius: 10px;
  }

  .course-pricing{
    font-weight: bold;

}

.course-pricing-{
    font-weight: 500;
    

}


  .ccta-text{
    font-weight: 500;
    width: 80%;
    color: rgba(0, 0, 0, 0.719);
    font-size: large;
  }

  .c-cta{
    margin-top: 1rem;
width: 10rem;
  }