.about-page{
    width: 100%;

}

.about-content{
    display: flex;
    flex-direction: column;
    padding-top: 5rem;
    padding-left: 12rem;
    padding-right: 12rem;
    background-color: whitesmoke;
}

.ab-title{
    color: rgb(53, 53, 53);
}


.ab-text{

}

.ab-section{
    display: flex;
    height: 20rem;
margin-top: 2rem;
}



.ab-image{
 /* background-color: green; */
    width: 100%;
    background-position: center;
    /* background-size: contain; */
    background-image: url("../assets/cc.jpg") ;
}

.more-ab{
    width: 100%;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}

.extras{
    margin-top: 2rem;
    margin-bottom: 5rem;
}


.ab-set-apart{

}