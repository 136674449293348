.loginHolder2{
    place-content: center;
    display: flex;
flex-direction: column;
background-color: #d6d6d67a;
height: 100vh;
}


.regBtn{
    width: 100% !important;
}


.regHolder{
    /* background-color: red; */
    display: flex;
    width: 100%;

}

.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  
  .loading-bar {
    width: 20%;
    background-color: #f3f3f3;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .loading-bar-fill {
    width: 0;
    height: 5px;
    background-color: #0a51d8;
    animation: loading 2s infinite;
  }
  
  @keyframes loading {
    0% { width: 0; }
    50% { width: 50%; }
    100% { width: 100%; }
  }
  


.btn-regesiter2{
    width: 100%;
    height: 40px;
    border-radius: 17px;
    color: #19497e;
    align-self: center;
}


.btn-regesiter2:hover{
    color: white;
}
.regImage{
    background-image: url("../assets/ttcon.png");
    /* background-color: blue; */
    width: 100%;
    background-position: center;
    background-size: cover;

}


.btn-login2{
    width: 50%;
    height: 40px;
    align-items: center;
    border-radius: 17px;
    /* background-color: #8da5fe;
    color: white; */
    display: flex;
    flex-direction: column;
}

.btn-login2:hover{
    background-color: #0a51d8;
    color: white;
}


.navis-logo2{
    align-self: center;
    width: 25%;
}

.navis-form2{
    align-self: center;
    background-color: rgba(179, 179, 184, 0.26);
    height: fit-content;
    border-radius: 5px;
    width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.btns-login2{
    margin-top: 5%;
width: 100%;

display: flex;
flex-direction: column;
gap:10px;
    }