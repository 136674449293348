.delivery-item{
    background-color: rgb(243, 242, 242) !important;
    text-decoration: none;
}

.deliveries-container{
    
}


/* deliver.css */
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #f0f0f0;
    border: none;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
  }
  