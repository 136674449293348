.footer{
    background-color: #1a4e8e;
    height: 60vh;
    width: 100%;
    position: relative;
    bottom: 0;
    padding-top: 2rem;
    display: flex;
flex-direction: column;
}


.useful_links{
    /* background-color: red; */
    width: 80%;
    gap: 10px;
    display: flex;

}

.ft-desc{
    text-align: left;
    margin-right: 1rem;
}

.top-footer{
    /* background-color: blueviolet; */
    display: flex;
    width: 100%;
    gap: 10px;
    height: 100%;
    padding-right: 12rem;
padding-left: 12rem;

}


.lower-footer{
    padding-left: 12rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-right: 12rem;
    background-color: #18467e;
    width: 100%;
}


.reach-out-section{
    /* background-color: aquamarine; */
    width: 20%;
    display: flex;
    margin-top: 3rem;
    flex-direction: column;
    /* justify-content: center; */
}

.footer-links{
    display: flex;
    width: 100%;
    /* justify-content: center; */
   margin-top: 3rem;
    /* background-color: #000; */
    flex-direction: column;
    color: whitesmoke;
}



.footer-links-heading{
    margin-bottom: 0px;   
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 500;
    letter-spacing: 0;
   opacity: 1;

    color: #aabee5;
    text-transform: none;
    margin-bottom: 5%;
    font-variant-numeric: unset;
}

.footer-link2{
    font-size: .8rem;
    line-height: 1.375rem;
    /* font-weight: 500; */
    letter-spacing: 0;
    text-transform: none;
    color: #8d9cb9;
    font-variant-numeric: unset;
}

.footer-link{
    font-size: .8rem;
    line-height: 1.375rem;
    /* font-weight: 500; */
    letter-spacing: 0;
    text-transform: none;
    color: #8d9cb9;
    font-variant-numeric: unset;
}

.footer-link:hover{
    color: white;
    cursor: pointer;
}


.link-img{
    /* background-color: red; */
    display: flex;
    align-items: center;
width: fit-content;
}

.arrow{
    height: fit-content;
    width: 12%;
    margin-left: 8%;
    display: none;
}

.link-img > button{
    border-radius: 17px;

}

.rc-btn{
    background-color: #216df0;
    margin-bottom: .5em;
    border-radius: 17px;
    color: #fff;  
    /* width: 20em; */
}

.rc-b tn:hover{
    background-color: #fff;
color: #216df0;
}


.white::after{
    background-image: url("../assets/2.jpg");
}

.footer-btn{
    background-color: #e3effb;
    margin-bottom: .5em;
    border-radius: 17px;
    color: #216df0;
    /* width: 20em; */

}


.footer-btn:hover{
background-color: #216df0;
color: #fff;
}


.lf-div{
    display: flex;
    color: #e3effb;
    justify-content: space-between;
}


.footer-socials{
    color: #8d9cb9;
    display: flex;
    align-items: center;
    gap: .8rem;
    font-size: .8rem;

}

.footer-socials > i{
    color: white;
display: flex;
}

i:hover{
    cursor: pointer;
}

.footer-socials:hover{
    cursor: pointer;
}


.footer-location{
   /* background-color: red; */
   color: #8d9cb9; 
   display: flex;
   gap: .7rem;
   align-items: center;
   font-size: .8rem;

}


.footer-location:hover{
    cursor: pointer;
}

.footer-location > i{
    /* background-color: red; */
    color: white; 
    display: flex;

    margin-right:5px;
    font-size: .8rem;
 
 }