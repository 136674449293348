.step-holder{
    background-color: rgb(223, 221, 221);
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.central-section{

}

