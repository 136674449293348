.top-content2 {
    display: flex;
    flex-direction: column;
    transition: background-color 0.5s ease;
  }
  
  .hero_section {
    height: 100vh;
    /* padding-top: 10%; */
    align-items: center;
    display: flex;
    background-color: white;
    padding-left: 12rem;
  }
  
  .hero_section2 {
    background-color: white;
    display: flex;
  }
  
  .hero_sec_subtitle {
    font-size: 2.5rem;
    line-height: 3.25rem;
    font-weight: 500;
    letter-spacing: 0;
    text-transform: none;
    font-variant-numeric: unset;
  }
  
  .items_section {
    margin-left: 12rem;
    width: 100%;
    height: 100%;
    margin-left: 12rem;
    margin-right: 12rem;
  }
  
  .item_holder {
    margin-top: 10%;
  }
  
  .hero_section3 {
    margin-bottom: 30px;
    /* height: 100vh; Or adjust to your preferred height */
    position: relative;
    background-color: aquamarine;
    overflow: hidden; /* Ensure overflow doesn't affect the appearance */
  }
  
  .white-line {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    background-color: white;
  }
  
  .circle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    
    height: 10px;
    background-color: rgb(255, 254, 254);
    border-radius: 50%;
  }
  .text_hero {
    display: flex;
    margin-top: -1rem;
    flex-direction: column;
  }
  
  .why-us {
    color: #216df0;
    font-size: 1rem;
    margin-top: 5%;
    line-height: 1rem;
    font-weight: 500;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    font-variant-numeric: unset;
  }
  
  .f-text {
    color: #216df0;
    margin-bottom: 2rem;
    max-width: 80%;
    margin-top: 0px;
    font-size: 4.075rem;
    line-height: 4rem;
    font-weight: 700;
    letter-spacing: -0.00625rem;
    text-transform: none;
  }
  
  .boost {
    color: rgb(46 58 52);
    width: 82%;
  }
  
  .imageside {
    background-image: url('../assets/hero_img.png');
    width: 100rem;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .hero_image {
    width: 100%;
  }
  
  .why_sub {
    font-size: 2rem;
    line-height: 4.25rem;
    font-weight: 300;
    /* margin: 0px; */
    letter-spacing: 0;
    text-transform: none;
    font-variant-numeric: unset;
  }
  
  .cubes {
    display: flex;
  }
  

  .right_map{
    margin-top: 5rem;
    width: 35%;
    margin-right: 12rem;
    float: right;
    margin-bottom: 10%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    /* background-color: red; */
  }

  .right_map2{
    /* background-color: red; */
    width: 35%;
    border-radius: 20px;
    margin-top: 5rem;
height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 12rem;
    float: left;
  }


  
.manage_drivers {
    background-color: white;
    border-radius: 20px;
    margin-bottom: 10%;
    align-self: center;
    width: 34rem !important;
    height: 35em;
}

  .driver_tt{
    width: 35em;

    height: 40%;
      /* background-image: url("../assets/drivers_map.png"); */
background-size: contain;
    background-repeat: no-repeat;

    }

  .map_drivers_title{
    background-color: white;
    display: flex;
    padding-left: 12rem;
    flex-direction: column;
    padding-bottom: 5rem;
    padding-right: 12rem;
}

.how_txt{
    color: #0a51d8;
    font-size: 1rem;
    margin-top: 3%;
    line-height: 1rem;
    font-weight: 500;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    font-variant-numeric: unset;

}

.sync_txt{
    font-size: 2rem;
    line-height: 4.25rem;
    font-weight: 300;
    margin-top: 0px;
    letter-spacing: 0;
    text-transform: none;
    font-variant-numeric: unset;
}


.right_map_content{
/* background-color: black; */
height: 35em;
display: flex;
flex-direction: column;
justify-content: center;
}


.track-title{
color: white;
font-size: 2.1rem;
        line-height: 2.05rem;
        font-weight: 500;
        width: 80%;
        margin: 0px;
        letter-spacing: 0;
        text-transform: none;
        font-variant-numeric: unset;
}

.am-desc{
    font-size: 1.150rem;
        line-height: 1.5rem;
        font-weight: 400;
        letter-spacing: 0;
        margin-top: 3%;
        color: white;
        text-transform: none;
        font-variant-numeric: unset;
}

.testHolder{
  padding-left: 12rem;
  padding-right: 12rem;
height: fit-content;
display: flex;
width: 100%;
padding-top: 5rem;
padding-bottom: 8rem;
background-color: #f5f7fb;
}

.trm{
  /* background-color: red; */
  height: fit-content;
  margin-top: 5%;
  /* width: 20%; */
  display: flex;
  padding: 5%;
  border-radius: 17px;
  border: 1px solid #0a51d8;
}

.trm_txt{
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  /* background-color: #0a51d8; */
}


#ic_trm{
  color: #0a51d8;
  margin-right: 2%;
  
}

.twg-txt{
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  padding-top: 3%;
  padding-right: 2rem;
}


.ai-powered-text{
  font-size: .9rem;
  line-height: 1rem;
  font-weight: 500;
  color: white;
  letter-spacing: .025em;
  text-transform: uppercase;
  font-variant-numeric: unset;
}

.ai-powered-text2{
line-height: 1rem;
color: white;
font-weight: 400;
letter-spacing: .025em;
font-variant-numeric: unset;
}

.tw-gradient{
  width: 100%;
  padding-left: 12rem;
  padding-top: 5rem;
  padding-bottom: 6rem;
  padding-right: 12rem;
  height: 100vh;
background-color: #f1f5fc;
}

.tw-gradient-top{
  border-radius: 20px;
  background-color: #3b71d7;
  width: 100%;
  margin-bottom: 2%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tw-gradient-inner-top{
  display: flex;
  background-color: white;
  width: 100%;
  height: 100%;
  margin-top: 3%;
  padding-right: 2rem;
  padding-left: 2rem;

  border-radius: 20px;
}

.twg-div1{
  display: flex;
  width: 50rem;
  justify-content: center;
  flex-direction: column;
}


.twg-title{
  font-size: 2.5rem;
        line-height: 2.75rem;
        font-weight: 500;
        letter-spacing: 0;
        text-transform: none;
        font-variant-numeric: unset;
        margin-bottom: 0px;
        margin-top: 0px;
  color: gray;
}

.twg-btn{
  margin-top: 2%;
  width: 30%;
}




.twg-subTitle{
  color: #19497e;
margin-top: 1%;
margin-bottom: 2%;  
  font-size: 1.5rem;
  line-height: 2.75rem;
  font-weight: 500;
  letter-spacing: 0;
  text-transform: none;
  font-variant-numeric: unset;
}

.twg-p{
margin-top: 0%;
margin-bottom: 3%;
width: 85%;
}

.trm_desc{
color: white;
}

.twg-btn{
  margin-top: 2%;
border-radius:  17px !important;
}

.trm_title{
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 500;
  letter-spacing: 0;
  text-transform: none;
  color: white;
  font-variant-numeric: unset;
}


.twg-images{
  width: 100%;
  height: 100%;
  gap: 5px;
  display: flex;
  padding: 1%;
}

.twg-image-one{
  width: 50%;
  height: 90%;
  border-radius: 10px;
}


.twg-image-one2{
  width: 55%;
  height: 90%;
  border-radius: 10px;
}

.getStarted{
  width: 100%;
  padding-left: 12rem;
  padding-right: 12rem;
  display: flex;
  padding-bottom: 10rem;

  align-items: center;
  background-image: url("../assets/bgg.png");
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-color: #216df0; */
}



.getStarted-title{
  font-size: 1rem;
  line-height: 1rem;
  margin-top: 12%;
  color: #e3effb;
  font-weight: 500;
  letter-spacing: .025em;
  text-transform: uppercase;
  font-variant-numeric: unset;
}


.reduce-text{
  font-size: 2.05rem;
  line-height: 2.5rem;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-top: 0px;
  letter-spacing: 0;
  margin-bottom: 0px;
  text-transform: none;
  width: 50%;
  font-variant-numeric: unset;
}


.start-here{
  margin-top: 2%;
  width: 15%;
  border-radius: 17px;
}

.start-here:hover{
  background-color: #216df0;
}

.shipment-image{
  width: 100%;
  border-radius: 12px;
  display: none;
  margin-top: 3%;
  margin-bottom: 3%;
  border: 5px solid rgb(230, 230, 230);
}