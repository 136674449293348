/* Main container styling */
.postcontent-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Container that holds the video and overlay */
.video-container {
    position: relative;
    width: 100%;
    height: 35rem;
    overflow: hidden;
}

/* Video styling */
.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.overlay-text{
    color: rgb(196, 193, 193);
    margin-bottom: 0px;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0px;
}
.overlay-text2{
    color: white;
    font-size: .9rem;
    color: rgb(196, 193, 193);
    font-weight: 100;
}

.spacer{
    /* background-color: red; */
    height: 1rem;
    width: 1rem;
}

.mystory-text {
    display: flex;
    flex-direction: column;
}

.mst-ptags{
    color: black !important;
}


.cta-ms{
    margin-bottom: 5rem;
    height: 20rem;
    display: flex;
    gap: 1em;
    align-items: center;
    background-image: url("../assets/code2.png");
    background-size: contain;
    width: 100%;
}

.cta-div2{
    background-color: transparent;
    width: 100%;
    height: 100%;
}

.vc-btn {
    align-self: left;
    width: 50%;
    border-radius: 20px;
}

.cta-text{
    margin-bottom: 1rem;
    text-align: left;
    font-size: 1rem;
    color: black;
    font-weight: 400;
}

.cta-div{
    /* background-color:#216df0; */
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    height: 100%;
}
/* Overlay div styling */
.overlay3 {
    position: relative;
    z-index: 2;
    background-color: rgba(29, 29, 29, 0.651);  
/* background-color: red; */
    height: 100%;
    width: 100%;
    display: flex;
    padding-right: 12rem;
    text-align: left;
    flex-direction: column;
    padding-left: 12rem;
    color: black;
    justify-content: space-between;
    padding-bottom: 4rem;
    font-size: 24px;
}

/* Content section styling */
.inner-postcontent {
    background-color: whitesmoke;
    width: 100%;
    
    color: white;
    padding-top: 5rem;
    padding-left: 12rem;
    padding-right: 12rem;
}


.ms-title{
    color: white;
    margin-bottom: 0px;
}
